import { ManagementActionsBuilder } from '@wix/yoshi-flow-editor';

import { EditorAppContext } from '../types';
import { navigateToSearchResultsPage } from '../searchResults';
import { openSearchResultsWidgetSettingsModal } from '../searchResultsWidgetSettings';
import { SettingsTab } from '../../components/SearchResults/Settings/types';

export function configureAppManager(
  appContext: EditorAppContext,
  managementActionsBuilder: ManagementActionsBuilder,
) {
  managementActionsBuilder.mainActions().addAction({
    icon: 'appManager_settingsAction',
    title: appContext.translate(
      'appManager.actions.customizeSearchResults.label',
    ),
    onClick: async () => {
      await navigateToSearchResultsPage(appContext);

      await openSearchResultsWidgetSettingsModal(appContext);
    },
  });

  managementActionsBuilder.customActions().addAction({
    icon: 'customizeDesignButtonBrush',
    title: appContext.translate('appManager.actions.customizeDesign.label'),
    type: 'editorActions',
    onClick: async () => {
      await navigateToSearchResultsPage(appContext);

      await openSearchResultsWidgetSettingsModal(
        appContext,
        SettingsTab.Design,
      );
    },
  });

  managementActionsBuilder.learnMoreAction().set({
    id: '1d4824c6-d3b7-4bbd-bc1e-25910dfc27e3',
  });
}
